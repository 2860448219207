var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Upload Youtube Video"}},[_c('b-overlay',{attrs:{"show":_vm.showOverlay,"rounded":"sm"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.storeAttachment)}}},[(_vm.validationErrors && _vm.validationErrors.message)?_c('b-alert',{attrs:{"variant":"danger","show":"","dismissible":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_c('strong',[_vm._v(_vm._s(_vm.validationErrors.message))])])])]):_vm._e(),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"mc-email","label":"Curso"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Curso"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.courses,"placeholder":"Select Course","reduce":function (course) { return course.id; }},model:{value:(_vm.material.course_id),callback:function ($$v) {_vm.$set(_vm.material, "course_id", $$v)},expression:"material.course_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Attachment Name","label-for":"mc-first-name"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Attachment Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-first-name","placeholder":"Attachment Name","name":"attachment_name"},model:{value:(_vm.material.attachment_name),callback:function ($$v) {_vm.$set(_vm.material, "attachment_name", $$v)},expression:"material.attachment_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"mc-email","label":"Month"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Month"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.months,"reduce":function (month) { return month.id; },"placeholder":"Select Month"},model:{value:(_vm.material.month_id),callback:function ($$v) {_vm.$set(_vm.material, "month_id", $$v)},expression:"material.month_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Youtube URL","label-for":"mc-first-name"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Youtube URL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mc-first-name","placeholder":"Youtube URL","name":"attachment_url"},model:{value:(_vm.material.attachment_url),callback:function ($$v) {_vm.$set(_vm.material, "attachment_url", $$v)},expression:"material.attachment_url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"md":"6"}},[(_vm.material.attachment_url != null)?_c('b-embed',{attrs:{"type":"iframe","aspect":"16by9","src":_vm.material.attachment_url}}):_vm._e()],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Upload ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }