<template>

  <b-card-code
    title="Upload Youtube Video"
  >
   <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
  <ValidationObserver v-slot="{ handleSubmit}">
  <b-form @submit.prevent="handleSubmit(storeAttachment)">
    <b-alert
      variant="danger"
      show
      dismissible
      v-if="validationErrors && validationErrors.message"
    >
      <div class="alert-body">
        <span><strong>{{validationErrors.message}}</strong></span>
      </div>
    </b-alert>
    <b-row class="mt-2">
    <b-col md="6">
        <b-form-group
            label-for="mc-email"
            label="Curso"
        >
        <ValidationProvider rules="required" name="Curso" v-slot="{ errors }">
          <v-select
            v-model="material.course_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="courses"
            placeholder = 'Select Course'
            :reduce="course => course.id"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </ValidationProvider>
        </b-form-group>
      </b-col>

        <b-col md="6">
          <b-form-group
            label="Attachment Name"
            label-for="mc-first-name"
          >
            <ValidationProvider rules="required" name="Attachment Name" v-slot="{ errors }">
              <b-form-input
                v-model="material.attachment_name"
                id="mc-first-name"
                placeholder="Attachment Name"
                name="attachment_name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label-for="mc-email"
              label="Month"
          >
          <ValidationProvider rules="required" name="Month" v-slot="{ errors }">
            <v-select
              v-model="material.month_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="months"
              :reduce="month => month.id"
              placeholder = 'Select Month'
            />
          <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
        </b-col>
        <b-col md="6" class="">
          <b-form-group
            label="Youtube URL"
            label-for="mc-first-name"
          >
            <ValidationProvider rules="required" name="Youtube URL" v-slot="{ errors }">
              <b-form-input
                v-model="material.attachment_url"
                id="mc-first-name"
                placeholder="Youtube URL"
                name="attachment_url"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
        </b-col>
        
        <b-col md="6" class="mb-2">
            <b-embed
                v-if="material.attachment_url != null"
                type="iframe"
                aspect="16by9"
                :src="material.attachment_url"
            ></b-embed>
        </b-col>
        
        <!-- submit and reset -->
       
      </b-row>
      <b-row>
       <b-col>
        
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Upload
          </b-button>
         
        </b-col>
        </b-row>
    </b-form>
  </ValidationObserver> 
  
  </b-overlay>
   </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BFormDatepicker, BBadge, BEmbed, BInputGroup, BAlert, BInputGroupAppend, BCol, BAvatar,BCardText, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BFormFile,BMedia, BMediaAside, BOverlay, BMediaBody, BLink, BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CourseService from '@/services/course.service.js';

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormFile,
    BCardText,
    BFormDatepicker,
    BOverlay,
    BInputGroup,
    BInputGroupAppend,
    BAlert,
    BBadge,
    BEmbed
  },
  directives: {
    Ripple,
  },
  data() {
    return {
        material: {
            attachment_name: '',
            attachment_url: null,
            course_id: '',
            type: 'video',
            month_id: ''
        },
        logoImg: require('@/assets/images/pages/logo-1.png'),
        avatar: '@/assets/images/portrait/small/avatar-s-11.jpg',
        showOverlay: false,
        errors: '',
        courses: [],
        months: [],
    }
  },
  computed: {
    validationErrors(){
      return this.errors.data
    },
  },
  methods: {
    storeAttachment() {  
      this.showOverlay = true;
          CourseService.storeVideo(this.material).then(response => {
            this.showOverlay = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Course Material Uploaded Successfully',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
                // this.$router.push('/courses');
            }).catch(error => {
              this.showOverlay = false;
              this.errors = error.response
            });
    },
    getCourses()
    {
      // CourseService.getAll().then(response => {
      //   this.courses = response.data.data.courses; 
      //   }).catch(error => {
      //       console.log(error)
      //   });
      CourseService.getAll().then(response => {
        let getCourses = response.data.data.courses;
        this.months = response.data.data.months;
        this.courses = getCourses.filter(course => {
            if(course.parent_id == null) {
                return course;
            }
        })
        }).catch(error => {
            console.log(error)
        });
    },
  },
  created() {
      this.getCourses();
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
